import axios, { AxiosResponse } from 'axios';
import { getBasicHeader, getBearerHeader } from '../utils/services';
import { BASE_URL } from './url';

function getAll(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getById(token: string, id: number): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/${id}`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getRoles(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/roles`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function insert(token: string, user: UserForm): Promise<AxiosResponse> {
  const url = `${BASE_URL}/register`;
  const data = { ...user, password: window.btoa(user.password) };
  return axios.post(url, data, { headers: getBearerHeader(token) });
}

function update(token: string, id:number, user: UserFormUpdate): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/${id}`;
  return axios.put(url, user, { headers: getBearerHeader(token) });
}

function changePassword(token: string, user: PasswordUserForm): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/password`;
  const data = new URLSearchParams({
    old: btoa(user.oldpassword),
    new: btoa(user.newpassword),
    confirm: btoa(user.confirmpassword),
  });

  return axios({
    url,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: `Bearer ${token}`,
    },
    data,
  });
}

function login(email: string, password: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/login`
  const token = window.btoa(`${email}:${password}`);
  return axios.post(url, {}, { headers: getBasicHeader(token) });
}

function remove(token: string, id: number): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/${id}`;
  return axios.delete(url, { headers: getBearerHeader(token) });
}

function getAllUsersPermissions(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/permissions`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function updatePermissions(token: string, id: number, milestoneIds: number[]): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/${id}/permissions`;
  return axios.put(url, { milestoneIds }, { headers: getBearerHeader(token) });
}

const UserService = {
  getAll,
  getById,
  getRoles,
  insert,
  update,
  changePassword,
  login,
  remove,
  getAllUsersPermissions,
  updatePermissions,
};

export default UserService;