import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StationState {
  chargers: Charger[],
}

const initialState: StationState = {
  chargers: [],
};

export const stationSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    setChargers: (state, { payload }: PayloadAction<Charger[]>) => {
      state.chargers = payload;
    },
  },
});
