import React, { useEffect } from 'react';
import { FocusStyleManager, Toaster } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import AppRoutes from './routes';
import { RootState } from './stores';

FocusStyleManager.onlyShowFocusOnTabs();

const AppToast = Toaster.create();
  
const App: React.FC = () => {
  const { toast } = useSelector((s: RootState) => s.ui);

  useEffect(() => {
    if (toast.message !== '') {
      AppToast.show({ timeout: 3000, ...toast });
    }
  }, [toast]);

  return (
    <div>
      <AppRoutes />
    </div>
  );
};

export default App;
