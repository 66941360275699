import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import station from './station';
import ui from './ui';
import user from './user';

export const store = configureStore({
  reducer: {
    station,
    ui,
    user,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> =
  ThunkAction<ReturnType, RootState, unknown, Action<string>>;