const SIDEBAR = 'diatera_sidebar';
const USER = 'diatera_user';

export function getUser(): User | null {
  const savedUser = localStorage.getItem(USER);
  if (savedUser == null) {
    return null;
  }

  try {
    return JSON.parse(atob(savedUser));
  } catch {
    return null;
  }
}

export function saveUser(user: User): void {
  const serializeUser = JSON.stringify(user);
  localStorage.setItem(USER, btoa(serializeUser));
}

export function clearLocalStorage(): void {
  localStorage.removeItem(USER);
}

export function getSidebar(): SidebarState | null {
  const state = localStorage.getItem(SIDEBAR);
  if (state !== 'expand' && state !== 'collapse') {
    return null;
  }

  return state;
}

export function saveSidebar(state: SidebarState): void {
  localStorage.setItem(SIDEBAR, state);
}

