import { H5 } from '@blueprintjs/core';
import React, { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { Routes, Route } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
import LoadingPage from '../components/layouts/LoadingPage';
import MainBase from '../components/layouts/MainBase';
import { RootState } from '../stores';

const load = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingPage />}>
    <Component {...props} />
  </Suspense>
);

const Charger = load(lazy(() => import('../pages/Charger')));
const ChargerKey = load(lazy(() => import('../pages/ChargerKey')));
const Dashboard = load(lazy(() => import('../pages/Dashboard')));
const EditCharger = load(lazy(() => import('../pages/EditCharger')));
const EnergyConversion = load(lazy(() => import('../pages/EnergyConversion')));
const LandingPage = load(lazy(() => import('../pages/LandingPage')));
const LandingStats = load(lazy(() => import('../pages/LandingStats')));
const LandingStations = load(lazy(() => import('../pages/LandingStations')));
const Login = load(lazy(() => import('../pages/Login')));
const MasterData = load(lazy(() => import('../pages/MasterData')));
const NewCharger = load(lazy(() => import('../pages/NewCharger')));
const PengelolaSPBKLU = load(lazy(() => import('../pages/PengelolaSPBKLU')));
const PengelolaSPKLU = load(lazy(() => import('../pages/PengelolaSPKLU')));
const Profile = load(lazy(() => import('../pages/Profile')));
const Recap = load(lazy(() => import('../pages/Recap')));
const Simulasi = load(lazy(() => import('../pages/Simulasi')));
const SiteMap = load(lazy(() => import('../pages/SiteMap')));
const Stations = load(lazy(() => import('../pages/Stations')));
const Users = load(lazy(() => import('../pages/Users')));

const MainPages: React.FC = () => {
  const { user } = useSelector((s: RootState) => s.user);
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname !== '/login' && user.id === 0) {
      navigate('/login');
    }
  }, [location, user, navigate]);

  return (
    <MainBase>
      {user.id && <ProtectedRoutes />}
    </MainBase>
  );
};

const Swagger: React.FC<{ spec?: string | object }> = ({ spec }) => (
  <div style={{ userSelect: 'text' }}>
    <SwaggerUI spec={spec} />
  </div>
);

const ProtectedRoutes: React.FC = () => {
  const { user } = useSelector((s: RootState) => s.user);

  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/energy" element={<EnergyConversion />} />
      <Route path="/map" element={<SiteMap />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/recap/*" element={<Recap />} />
      <Route path="/stations/*" element={<Stations />} />
      <Route path="/stations/:stationId/chargers/new" element={<NewCharger />} />
      <Route path="/stations/:stationId/chargers/:id" element={<Charger />} />
      <Route path="/stations/:stationId/chargers/:id/edit" element={<EditCharger />} />
      <Route path="/stations/:stationId/chargers/:id/key" element={<ChargerKey />} />

      {user.accessLevel === 0 && (
        <>
          <Route path="/data/*" element={<MasterData />} />
          <Route path="/data-spklu/*" element={<PengelolaSPKLU />} />
          <Route path="/data-spbklu/*" element={<PengelolaSPBKLU />} />
          <Route path="/users/*" element={<Users />} />
        </>
      )}

      <Route path="*" element={<H5>Page Not Found</H5>} />
    </Routes>
  );
};

const AppRoutes: React.FC = () =>  {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/landing-stations" element={<LandingStations />} />
      <Route path="/login" element={<Login />} />
      <Route path="/simulasi" element={<Simulasi />} />
      <Route path="/stats" element={<LandingStats />} />
      <Route path="/docs/management" element={<Swagger spec={require('../docs/dashboard.json')} />} />
      <Route path="/docs/device" element={<Swagger spec={require('../docs/device.json')} />} />
      <Route path="/docs/app" element={<Swagger spec={require('../docs/app.json')} />} />
      <Route path="*" element={<MainPages />} />
    </Routes>
)};

export default AppRoutes;
