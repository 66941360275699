import { AnchorButton, Button, Navbar } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import NoNotif from "../../elements/NoNotif";
import { RootState } from "../../../stores";
import styles from "./header.module.css";
import HeaderContext from "./HeaderContext";

interface Props {
  className?: string;
  collapse?: boolean;
}

const Header: React.FC<Props> = ({ className, collapse }) => {
  const { content } = useContext(HeaderContext);
  const { fullname } = useSelector((s: RootState) => s.user.user);

  return (
    <>
      <div className={className}>
        <Navbar className={styles.navbar}>
          <Navbar.Group className={collapse ? styles.collapse : styles.expand}>
            {/* <div style={{ flex: collapse ? 1 : 0 }}>
              <img
                className={styles.logo}
                width={32}
                // height={42}
                src={Logo}
                alt="Kementerian ESDM"
              />
            </div>
            {!collapse && (
              <H5 style={{ marginBottom: 0, flex: 1, color: "#ffffff" }}>
                SPKLU-SPBKLU
              </H5>
            )}
            <Navbar.Divider style={{ margin: 0 }} />
            <Link to="/" className={styles.title}>
              <div style={{ flex: collapse ? 1 : 0 }}>
                <img
                  className={styles.logo}
                  height={18}
                  src={Logo}
                  alt="Kementerian ESDM"
                />
              </div>
              {!collapse && (
                <H5 style={{ marginBottom: 0, flex: 1 }}>SPKLU-SPBKLU</H5>
              )}
            </Link> */}
          </Navbar.Group>

          <Navbar.Group>{content}</Navbar.Group>

          <Navbar.Group align="right">
            <Popover2
              className={styles.desktop}
              content={<NoNotif />}
              interactionKind="click"
              placement="bottom-end"
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <Button
                  {...targetProps}
                  minimal
                  elementRef={ref!}
                  icon="notifications"
                  intent="warning"
                  color="#ffffff"
                  style={{ color: "#ffffff" }}
                />
              )}
            />
            <Navbar.Divider className={styles.desktop} />
            <AnchorButton
              minimal
              className={styles.desktop}
              href="#/profile"
              icon="user"
              text={fullname}
              intent="warning"
              color="#ffffff"
              style={{ color: "#ffffff" }}
            />
          </Navbar.Group>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
