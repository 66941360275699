import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUser } from '../../utils/storage';

interface UserState {
  user: User,
  userList: User[],
}

const initialState: UserState = {
  user: {
    email: '',
    fullname: '',
    id: 0,
    accessLevel: 0,
    token: '',
  },
  userList: [],
};

const getInitialState = (): UserState => {
  const user = getUser();
  if (user == null) {
    return initialState;
  }

  return { ...initialState, user };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
    setUserList: (state, { payload }: PayloadAction<User[]>) => {
      state.userList = payload;
    },
  },
});
