import { setChargers } from '.';
import { AppThunk } from '..';
import ChargerService from '../../services/chargers';
import { setLoading, setMessage } from '../ui';

export function getChargers(): AppThunk {
  return async (dispatch, getState) => {
    const { user } = getState().user;

    dispatch(setLoading(true));
    try {
      const { data } = await ChargerService.getAll(user.token);
      if (data.response_code === 0) {
        dispatch(setChargers(data.data));
      } else {
        dispatch(setMessage({ intent: 'danger', message: data.message }));
      }
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
    }
    dispatch(setLoading(false));
  };
}
