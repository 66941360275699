import axios, { AxiosResponse } from 'axios';
import { getBearerHeader } from '../utils/services';
import { BASE_URL } from './url';

function getAll(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/chargers`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getCurrentValues(token: string, chargerId: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/chargers/${chargerId}/values`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getDailySummary(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/chargers/daily`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getKey(token: string, chargerId: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/chargers/${chargerId}/key`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function generateKey(token: string, chargerId: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/chargers/${chargerId}/key`;
  return axios.post(url, null, { headers: getBearerHeader(token) });
}

function insert(token: string, charger: ChargerFormBody): Promise<AxiosResponse> {
  const url = `${BASE_URL}/chargers`;
  return axios.post(url, charger, { headers: getBearerHeader(token) });
}

function topup(token: string, topup: TopupForm): Promise<AxiosResponse> {
  const url = `${BASE_URL}/chargers/topup`;
  return axios.put(url, topup, { headers: getBearerHeader(token) });
}

function update(token: string, charger: ChargerFormBody): Promise<AxiosResponse> {
  const url = `${BASE_URL}/chargers/${charger.id}`;
  return axios.put(url, charger, { headers: getBearerHeader(token) });
}

const ChargerService = {
  getAll,
  getCurrentValues,
  getDailySummary,
  getKey,
  generateKey,
  insert,
  topup,
  update,
};

export default ChargerService;
