import { Alignment, AnchorButton, Icon, MaybeElement } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import React from "react";
import { useLocation } from "react-router";
import styles from "./sidebarbutton.module.css";

interface Props {
  collapse?: boolean;
  href?: string;
  icon?: IconName | MaybeElement;
  rightIcon?: IconName | MaybeElement;
  ali?: Alignment;
  fixColor?: string;
  onClick?: () => void;
}

const SidebarButton: React.FC<Props> = (props) => {
  const { collapse, children, href, icon, onClick, rightIcon, ali } = props;
  const location = useLocation();

  const active =
    `/${location.pathname.split("/")[1]}` === href?.substr(1, href.length);
  const color = active ? "#D8E1E8" : "#D8E1E8";
  const colorIcon = active ? "#FFC940" : "#D8E1E8";
  const backgroundColor = active ? "#3f444b" : "unset";

  return (
    <AnchorButton
      id={active ? "menuListActive" : ""}
      alignText={ali}
      fill
      large
      minimal
      className={styles.btn}
      href={href}
      icon={<Icon color={colorIcon} icon={icon} />}
      rightIcon={<Icon color={colorIcon} icon={rightIcon} />}
      style={{ color: color, backgroundColor }}
      onClick={onClick}
    >
      {collapse ? " " : children}
    </AnchorButton>
  );
};

export default SidebarButton;
