import { Alignment, AnchorButton, Icon, MaybeElement } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import React from 'react';
import { useLocation } from 'react-router';
import styles from './sidebararrowbutton.module.css';

interface Props {
  href?: string,
  icon?: IconName | MaybeElement,
  rightIcon?: IconName | MaybeElement,
  ali?: Alignment,
  onClick?: () => void,
  children?: any,
}

const SidebarArrowButton: React.FC<Props> = (props : Props) => {
  const { children, href, icon, onClick, rightIcon, ali } = props;
  const location = useLocation();

  const active = `/${location.pathname.split('/')[1]}` === href?.substr(1, href.length);
  const color = '#8e959b' ;
  const bgColor = active ? '#3f444b' : 'none';

  return (
    <AnchorButton
      alignText={ali}
      fill
      large
      minimal
      className={styles.btn}
      href={href}
      icon={<Icon color={color} icon={icon} />}
      rightIcon={<Icon color={color} icon={rightIcon} />}
      style={{ color: color, background:bgColor }}
      onClick={onClick}
    >
      {children}
    </AnchorButton>
  );
};

export default SidebarArrowButton;
