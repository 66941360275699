import React from "react";
import { useSelector } from "react-redux";
import SidebarButton from "../../elements/SidebarButton";
import SidebarArrowButton from "../../elements/SidebarArrowButton";
import { RootState } from "../../../stores";
import { SidebarProps } from "./interface";
import styles from "./sidebar.module.css";
import { H5 } from "@blueprintjs/core";
import Logo from "../../../assets/logo192.png";
import { Link } from "react-router-dom";

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const { sidebar } = useSelector((s: RootState) => s.ui);
  const { user } = useSelector((s: RootState) => s.user);
  const { onCollapse } = props;

  return (
    <div className={styles.root}>
      <div className={styles.topSidebar}>
        {sidebar === "expand" ? (
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div className={styles.topSecondSidebar}>
                <img
                  className={styles.logo}
                  width={42}
                  src={Logo}
                  alt="Kementerian ESDM"
                />
                <H5 style={{ marginBottom: 10, color: "#ffffff" }}>SPKLU-SPBKLU</H5>
            </div>
          </Link>
        ) : (
          <div>
            <Link to="/">
              <img
                className={styles.logo2}
                width={42}
                src={Logo}
                alt="Kementerian ESDM"
              />
            </Link>
          </div>
        )}
      </div>
      <div className={styles.line}></div>
      <div className={styles.menuSide}>
        <div className={styles.arrow}>
          {sidebar === "expand" ? (
            <SidebarArrowButton
              rightIcon="double-chevron-left"
              onClick={onCollapse}
              ali="right"
            >
              Navigation
            </SidebarArrowButton>
          ) : (
            <SidebarArrowButton
              icon="double-chevron-right"
              onClick={onCollapse}
            >
              &nbsp;
            </SidebarArrowButton>
          )}
        </div>
        <div className={styles.menu}>
          <SidebarButton
            collapse={sidebar === "collapse"}
            href="#/dashboard"
            icon="dashboard"
          >
            Dashboard
          </SidebarButton>
          <SidebarButton
            collapse={sidebar === "collapse"}
            href="#/stations"
            icon="geolocation"
          >
            Stations
          </SidebarButton>
          <SidebarButton
            collapse={sidebar === "collapse"}
            href="#/map"
            icon="map"
          >
            Site Map
          </SidebarButton>
          <SidebarButton
            collapse={sidebar === "collapse"}
            href="#/energy"
            icon="offline"
          >
            Konversi Energi
          </SidebarButton>
          <SidebarButton
            collapse={sidebar === "collapse"}
            href="#/recap"
            icon="book"
          >
            Rekapitulasi
          </SidebarButton>
        </div>
        <div className={styles.line}></div>
        {user.accessLevel === 0 && (
          <div className={styles.menu}>
            {/* <H5
              className={Classes.TEXT_MUTED}
              style={{
                fontWeight: 400,
                padding: "10px 22px",
                borderTop: "1px solid #ddd",
                borderBottom: "1px solid #ddd",
              }}
            >
              {sidebar === "collapse" ? <Icon icon="cog" /> : "Settings"}
            </H5> */}

            <SidebarButton
              collapse={sidebar === "collapse"}
              href="#/data-spklu"
              icon="briefcase"
            >
              Pengelola SPKLU
            </SidebarButton>

            <SidebarButton
              collapse={sidebar === "collapse"}
              href="#/data-spbklu"
              icon="briefcase"
            >
              Pengelola SPBKLU
            </SidebarButton>

            <SidebarButton
              collapse={sidebar === "collapse"}
              href="#/users"
              icon="people"
            >
              User Management
            </SidebarButton>

            {/* 
                <SidebarButton collapse={sidebar === 'collapse'} href="#/data" icon="database">          
                  Master Data
                </SidebarButton>
              */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
